import React from 'react';
import PropTypes from 'prop-types';

const Tab = props => {
  const { tabState, onUpdate } = props;
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const onClickTab = newTab => {
    const newTabState = tabState.map(t => ({ ...t, current: newTab === t.name }));
    onUpdate(newTabState);
  };

  return (
    <div>
      <div className="block">
        <nav aria-label="Tabs" className="flex space-x-4">
          {tabState.map(tab => (
            <button
              key={tab.name}
              aria-current={tab.current ? 'page' : undefined}
              className={classNames(
                tab.current
                  ? 'bg-kopirun-green bg-opacity-20 text-kopirun-green font-semibold'
                  : 'text-gray-500 text-lg hover:text-gray-700',
                'px-3 py-2 font-medium text-lg rounded-md'
              )}
              type="button"
              value={tab.name}
              onClick={e => {
                onClickTab(e.target.value);
              }}>
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

Tab.propTypes = {
  tabState: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default Tab;
