import React, { useState } from 'react';
import HeroImage from 'assets/main/heroimage.svg';

import Tab from 'components/Tab';

import Business from './business';
import Consumer from './consumer';

const Hero = () => {
  const tabs = [
    { name: 'For Consumers', current: true, view: <Consumer key="consumer" /> },
    { name: 'For Businesses', current: false, view: <Business key="business" /> }
  ];

  const [tabState, setTabState] = useState(tabs);

  return (
    <div className="flex flex-col md:flex-row justify-center items-center">
      <div className="flex-1">
        <div className="relative pb-8 bg-white mb-20 sm:pb-16 md:pb-20 lg:max-w-xl lg:w-full lg:pb-28 xl:pb-32">
          <section className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <Tab tabState={tabState} onUpdate={setTabState} />
            {tabState.map(tab => (tab.current ? tab.view : null))}
          </section>
        </div>
      </div>
      <div className="flex-1 mx-8 md:ml-auto md:mx-auto md:mr-6">
        <img alt="KopiRun App Screens" className="mx-auto" src={HeroImage} />
      </div>
    </div>
  );
};

export default Hero;
