import React from 'react';
import Apple from 'assets/apple.svg';
import Google from 'assets/google.svg';

const Business = () => (
  <>
    <h1 className="text-4xl tracking-tight font-bold text-kopirun-green sm:text-3xl md:text-4xl mt-5">
      <span className="block lg:inline">Fulfil more orders with each delivery trip.</span>
    </h1>
    <p className="mt-3 text-base sm:mt-5 sm:text-lg sm:max-w-xl md:mt-5 md:text-xl lg:mx-0">
      Collect more orders in a place you&#39;re already delivering to. More orders for you, cheaper
      delivery for your customers.
    </p>
    <div className="flex mt-5 sm:mt-8 lg:justify-start">
      {/* UNCOMMENT BELOW ONCE WE HAVE BUSINESS PAGES UP */}
      {/* <div className="rounded-md shadow">
                    <a
                        href="#"
                        className="flex items-center justify-center px-8 py-3 border border-transparent text-base font-semibold rounded-md text-white bg-kopirun-green hover:bg-kopirun-green md:py-4 md:text-lg md:px-10"
                    >
                        Get Started
                    </a>
                </div>
                <div className="ml-3">
                    <a
                        href="#"
                        className="flex items-center justify-center px-8 py-3 border border-transparent text-base font-semibold rounded-md text-kopirun-green bg-kopirun-green-light md:py-4 md:text-lg md:px-10"
                    >
                        Learn More
                    </a>
                </div> */}
      <div className="md:flex-none">
        <a
          href="https://apps.apple.com/app/id1512434287"
          rel="nofollow noopener noreferrer"
          target="_blank">
          <img
            alt="KopiRun - App Store"
            className="object-contain w-36 md:w-44 lg:w-44"
            src={Apple}
          />
        </a>
      </div>
      <div className="md:flex-none ml-3 md:ml-3 lg:ml-3">
        <a
          href="https://play.google.com/store/apps/details?id=com.kopirun.app"
          rel="nofollow noopener noreferrer"
          target="_blank">
          <img
            alt="KopiRun - Play Store"
            className="object-contain w-36 md:w-44 lg:w-44"
            src={Google}
          />
        </a>
      </div>
    </div>
  </>
);

export default Business;
