import React from 'react';
import Apple from 'assets/apple.svg';
import Google from 'assets/google.svg';

const Consumer = () => (
  <>
    <h1 className="text-4xl tracking-tight font-bold text-kopirun-green sm:text-3xl md:text-4xl mt-5">
      <span className="block lg:inline">Organize and join group buys with KopiRun.</span>
    </h1>
    <p className="mt-3 text-base sm:mt-5 sm:text-lg sm:max-w-xl md:mt-5 md:text-xl lg:mx-0">
      Meet a minimum order value with the help of your neighbours. Or dabao for others and earn some
      money. Start a group buy on KopiRun today.
    </p>
    <div className="flex mt-5 sm:mt-8 lg:justify-start">
      <div className="md:flex-none">
        <a
          href="https://apps.apple.com/app/id1512434287"
          rel="nofollow noopener noreferrer"
          target="_blank">
          <img
            alt="KopiRun - App Store"
            className="object-contain w-36 md:w-40 lg:w-44"
            src={Apple}
          />
        </a>
      </div>
      <div className="md:flex-none ml-3 md:ml-3 lg:ml-3">
        <a
          href="https://play.google.com/store/apps/details?id=com.kopirun.app"
          rel="nofollow noopener noreferrer"
          target="_blank">
          <img
            alt="KopiRun - Play Store"
            className="object-contain w-36 md:w-40 lg:w-44"
            src={Google}
          />
        </a>
      </div>
    </div>
  </>
);

export default Consumer;
